var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-layout",
    {
      staticClass: "text-input-mission",
      attrs: { column: "", "justify-center": "" },
    },
    [
      _c(
        "v-flex",
        { staticClass: "mission-instructions", attrs: { "d-flex": "" } },
        [
          _c("ResizableText", {
            ref: "instructions",
            attrs: { message: _vm.instructions, transition: false },
          }),
        ],
        1
      ),
      _c(
        "transition",
        { attrs: { name: "text-input-mission__transition" } },
        [
          _vm.mode !== "explain"
            ? _c(
                "v-flex",
                { class: { mobile: _vm.mobile }, attrs: { shrink: "" } },
                [
                  _c("TextField", {
                    staticClass: "mission-text-feild",
                    attrs: {
                      placeholder: _vm.placeholder,
                      status: _vm.status,
                      show: "show",
                      type: _vm.fieldType,
                      step: ".01",
                      disabled: _vm.missionCompleted,
                      buttonText: _vm.submitText,
                      maxTextLength: _vm.maxTextLength,
                    },
                    on: { onSubmit: _vm.onSubmit },
                  }),
                  _vm.mission.highestNumber && _vm.mobile
                    ? _c("TextField", {
                        staticClass: "mission-text-feild",
                        attrs: {
                          placeholder: _vm.passwordPlaceholder,
                          status: _vm.status,
                          show: "show",
                          type: _vm.fieldType,
                          step: ".01",
                          disabled: _vm.missionCompleted,
                          maxTextLength: _vm.maxTextLength,
                        },
                        on: { onSubmit: _vm.onSubmit },
                        model: {
                          value: _vm.text1,
                          callback: function ($$v) {
                            _vm.text1 = $$v
                          },
                          expression: "text1",
                        },
                      })
                    : _vm._e(),
                ],
                1
              )
            : _vm._e(),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }