<template>
  <v-layout column justify-center class="text-input-mission">
    <!-- Mission question -->
    <v-flex d-flex class="mission-instructions">
      <ResizableText
        :message="instructions"
        :transition="false"
        ref="instructions"
      />
    </v-flex>
    <transition name="text-input-mission__transition">
      <v-flex shrink v-if="mode !== 'explain'" :class="{ mobile }">
        <TextField
          class="mission-text-feild"
          :placeholder="placeholder"
          :status="status"
          :show="'show'"
          :type="fieldType"
          step=".01"
          :disabled="missionCompleted"
          :buttonText="submitText"
          @onSubmit="onSubmit"
          :maxTextLength="maxTextLength"
        />
        <TextField
          v-if="mission.highestNumber && mobile"
          class="mission-text-feild"
          :placeholder="passwordPlaceholder"
          :status="status"
          :show="'show'"
          v-model="text1"
          :type="fieldType"
          step=".01"
          :disabled="missionCompleted"
          @onSubmit="onSubmit"
          :maxTextLength="maxTextLength"
        />
      </v-flex>
    </transition>
  </v-layout>
</template>

<script>
import { mapGetters } from "vuex"
import { GameMixin } from "@/mixins"
import ResizableText from "@/components/GroupTeams/Common/Games/ResizableText.vue"
import TextField from "./TextField.vue"
import HubSpot from "@/services/hubspot.service"
import MissionType from "@shared/enums/Mission"
import Mission from "@shared/Mission"
import isUserSubmitMode from "@shared/helpers/isUserSubmitMode"

export default {
  name: "TextInput",
  mixins: [GameMixin],

  components: {
    ResizableText,
    TextField
  },
  model: {
    prop: "value",
    event: "change"
  },
  props: {
    value: String,
    mode: String,
    mission: Object,
    maxTextLength: {
      type: Number,
      default: null
    },
    mobile: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      text1: null
    }
  },
  computed: {
    ...mapGetters("group", ["isViewerHostLike"]),
    ...mapGetters("auth", [
      "isAudit",
      "isSpectator",
      "isHost",
      "user",
      "client"
    ]),
    ...mapGetters([
      "missionFail",
      "isScribe",
      "missionSuccess",
      "missionCompleted",
      "missionPlayType",
      "missionPlaysArray",
      "missionAnswers",
      "team",
      "game"
    ]),
    isPaused() {
      return (
        this.$store.getters["auth/isHybridRoom"] &&
        Mission.isPausable(this.mission) &&
        this.$store.getters.gameStatus?.paused !== true
      )
    },
    isMissionSuccess() {
      if (this.isPaused) return false
      return this.missionSuccess
    },
    isMissionFail() {
      if (this.isPaused) return false
      return this.missionFail
    },
    submitText() {
      return this.status === "correct" || this.missionCompleted
        ? "SENT"
        : "SUBMIT"
    },
    isTeamMission() {
      return this.missionPlayType === "team"
    },
    instructions() {
      return this.mission.instructions
    },
    correct() {
      return this.mission.answer ?? ""
    },
    behavior() {
      return this.mission.behavior
    },
    isFreeFormMissionBehavior() {
      return MissionType.FreeForm === this.mission.behavior
    },
    isFamilyFeudMission() {
      return this.mission.behavior === MissionType.FamilyFeud
    },
    isPriceIsRight() {
      return this.behavior === MissionType.PriceIsRight
    },
    fieldType() {
      return this.isPriceIsRight && "number"
    },
    canPlay() {
      if (this.isAudit || this.isAudit) return false
      return isUserSubmitMode(this.mode)
    },
    status() {
      if (this.isMissionSuccess) return "correct"

      if (this.isMissionFail) return "wrong"

      if (
        !this.isHost &&
        !this.missionCompleted &&
        this.isScribe &&
        this.canPlay
      )
        return "active"

      if (this.isAudit || this.isSpectator) return "You are auditing"

      return ""
    },
    lineThroughClass() {
      return this.missionFail ||
        (this.missionAnswersLength > 0 && !this.missionSuccess)
        ? " line-through"
        : ""
    },
    missionAnswersLength() {
      return this.missionAnswers.length
    },
    lastAnswer() {
      return this.missionAnswers[this.missionAnswersLength - 1]
    },
    passwordPlaceholder() {
      if (this.missionCompleted) {
        return "Sent!"
      } else {
        return "Host Password"
      }
    },
    placeholder() {
      // always show the answer to the host
      if (
        this.isHost ||
        this.isAudit ||
        this.isSpectator ||
        this.game.showAnswers
      )
        return this.correct !== "" ? `Answer: ${this.correct}` : ""

      if (this.isMissionSuccess && this.missionCompleted) return this.lastAnswer

      if (this.isMissionFail && this.missionCompleted)
        return this.correct.split(",")[0]

      if (!this.isScribe) return "Waiting for scribe to answer..."

      if (this.isTeamMission && this.missionAnswersLength) {
        // set the placeholder message for a non scribe
        // to the latest enetered by a scribe
        const answer = this.lastAnswer
        return typeof answer === "string" ? answer : ""
      }

      if (this.canPlay) return "Enter here..."

      // scribe but not ready to go
      return "Wait..."
    }
  },
  watch: {
    mode(value) {
      if (value !== "explain") {
        setTimeout(() => {
          this.$refs.instructions.fit()
        }, 900)
      }
    }
  },
  methods: {
    async onSubmit(data) {
      if (this.value !== undefined) {
        return this.$emit("change", data)
      }

      if (!this.isScribe || this.missionCompleted)
        return console.log("No rights for submit")
      if (!data) return console.log("Won't submit an empty string")
      this.answer = data

      if (this.isFreeFormMissionBehavior && HubSpot.EMAIL_PATTERN.test(data)) {
        HubSpot.identify({ ...this.user, email: data })
      }

      if (this.isFamilyFeudMission) {
        this.answer = data.toLowerCase().trim()
      }
      if (this.mission.highestNumber) {
        if (this.text1 != this.mission.hostPassword) {
          return
        }
      }

      const normalize = string => (string + "").toLowerCase().trim()

      if (
        this.mission.behavior == MissionType.TeamName &&
        this.missionPlaysArray.some(
          play => normalize(play.correct) === normalize(data)
        )
      ) {
        return this.$info(
          "Sorry, that name is already taken, please try again."
        )
      }

      await this.checkAnswer()
      this.answer = ""
    }
  }
}
</script>

<style lang="scss">
.text-input-mission {
  &__transition {
    &-enter-active,
    &-leave-active {
      transition: all ease-in-out 0.8s;
      position: relative;
      max-height: 160px;
    }
    &-enter,
    &-leave-to {
      opacity: 0;
      max-height: 0;
    }
  }
  .mission-instructions {
    padding: 25px 10px 5px;
    height: 0;
  }
  .mission-text-feild {
    position: relative;
    width: 85%;
    margin: auto;
    padding-bottom: 22px;
    visibility: hidden;
    opacity: 0;
    transition: all 0.25s ease-out;
  }
}
</style>
